








import Vue from "vue";
import Component from "vue-class-component";
import {BTabs, BTab, BButton, BvComponent} from "bootstrap-vue";
import {Ref, Watch} from "vue-property-decorator";
import {Route} from "vue-router/types/router";
import { State } from "vuex-class";

@Component<TabsComponent>({
  components: {
    BTabs,
    BTab
  },
  props: {
    baseRoute: {
      type: Object,
    }
  },
  watch: {
    $route($to: Route, from) {
      var routeIndex = this.tabNameIndex.get($to.hash.replace('#', ''));

      if (routeIndex !== undefined) {
        this.currentRouteIndex = routeIndex;
      }
    }
  }
})
export default class TabsComponent extends Vue {
  @Ref('rootTabs')
  btabsComponent!: BTabs

  tabNameIndex = new Map();

  currentRouteIndex = 0;


  async changed(index) {
    var currentTab = this.btabsComponent.tabs[index] as BTab
    await this.btabsComponent.$nextTick()
    await this.$nextTick()
    
    var routeName = (currentTab.title ? currentTab.title : (this.btabsComponent.getButtonForTab(currentTab).$el.innerText)).replace(/\s+/g, '-').toLowerCase();

    if (currentTab.$attrs.to) {
      await this.$router.push(currentTab.$attrs.to).catch(error => {
        console.log(error)
      });
    } else if (this.$route.hash !== "#" + routeName) {
      var route = this.$router.resolve(this.$props.baseRoute || {})?.route;
      await this.$router.push({path: route?.path, params: route?.params, hash: routeName})
    }
  }

  async mounted() {
    await this.$nextTick();
    (this.btabsComponent.$children.filter(x => (x as BvComponent).tab) as BButton[]).forEach((button, index) => {
      if (button.tab.$attrs.to) {
        this.tabNameIndex.set((button.tab.$attrs.to as Route), index);
      } else {
        this.tabNameIndex.set((button.tab.title ? button.tab.title : (button.$el as HTMLButtonElement).innerText).replace(/\s+/g, '-').toLowerCase(), index);
      }
    })

    

    for (const [key, value] of (this.tabNameIndex.entries())) {
      if (typeof key === 'string' && key.toLowerCase() === this.$route.hash.replace('#', '').toLowerCase()) {
        this.currentRouteIndex = value;

      } else {
        var route = this.$router.resolve(key);
        if (route.route.path === this.$route.path) {
          this.currentRouteIndex = value;
        }
      }
    }
  }
  
  @State((state) => state.reloadTabs.reload)
  reload!: boolean;

  private showTabs = true;
  @Watch('reload')
  reloadTabs() {
    this.showTabs = false;
    this.$nextTick(() => {
      this.showTabs = true;
    });
  }
  


}
